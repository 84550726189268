@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Inter", sans-serif;
}

/* Sidebar Menu */
.nav-menu:hover > .nav-icon {
  color: #fff;
}

.nav-menu.nav-active > .nav-icon {
  color: #fff;
}

.nav-menu {
  @apply hover:bg-primary hover:text-white focus:!bg-primary focus:!text-white active:!bg-primary active:!text-white;
}
/* Sidebar Menu */

.animationTab {
  animation: tabAnimation 0.4s ease 1;
}

@keyframes tabAnimation {
  0% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

.react-time-picker__wrapper {
  border: none !important;
}

.ck-editor__editable_inline {
  padding: 0 30px !important;
}

.loading-indicator:before {
  content: "";
  background: #00000080;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading-indicator:after {
  content: " ";
  position: fixed;
  top: 40%;
  left: 45%;
  z-index: 10010;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.2rem;
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #0474bf; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

/* Custom scrollbar styles */
::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-track {
  background: #f1f1f1; /* Background of the scrollbar track */
}

::-webkit-scrollbar-thumb {
  background: #c0c0c0; /* Color of the scrollbar thumb */
  border-radius: 10px; /* Rounded corners for the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover {
  background: #b1b1b1; /* Color of the scrollbar thumb on hover */
}
